<template>
  <div>
    <header class="vff-header">
      <div class="f-container">
        <svg fill="#FFFFFF" height="100" width="100" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 512 512" xml:space="preserve">
          <g>
            <g>
              <g>
                <path d="M324.267,332.8c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V332.8z"/>
                <path d="M315.733,426.667c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V435.2
                  C324.267,430.49,320.444,426.667,315.733,426.667z"/>
                <path d="M358.4,230.4c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V230.4z"/>
                <path d="M324.267,281.6c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V281.6z"/>
                <path d="M324.267,384c0-4.71-3.823-8.533-8.533-8.533S307.2,379.29,307.2,384v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V384z"/>
                <path d="M358.4,281.6c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V281.6z"/>
                <path d="M290.133,230.4c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V230.4z"/>
                <path d="M324.267,230.4c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V230.4z"/>
                <path d="M358.4,145.067V128c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  S358.4,149.777,358.4,145.067z"/>
                <path d="M290.133,281.6c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V281.6z"/>
                <path d="M392.533,128c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V128z"/>
                <path d="M281.6,324.267c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V332.8
                  C290.133,328.09,286.31,324.267,281.6,324.267z"/>
                <path d="M426.667,384c0-4.71-3.823-8.533-8.533-8.533S409.6,379.29,409.6,384v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V384z"/>
                <path d="M418.133,426.667c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V435.2
                  C426.667,430.49,422.844,426.667,418.133,426.667z"/>
                <path d="M452.267,324.267c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  c4.71,0,8.533-3.823,8.533-8.533V332.8C460.8,328.09,456.977,324.267,452.267,324.267z"/>
                <path d="M315.733,153.6c4.71,0,8.533-3.823,8.533-8.533v-36.002L409.6,87.731V230.4c0,3.558,2.21,6.741,5.538,7.996
                  l62.729,23.518v87.953c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V256c0-3.558-2.21-6.741-5.538-7.996
                  l-62.729-23.518V59.733c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v10.402l-95.94,23.987
                  c-3.797,0.947-6.46,4.361-6.46,8.277v42.667C307.2,149.777,311.023,153.6,315.733,153.6z"/>
                <path d="M460.8,281.6c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  c4.71,0,8.533-3.823,8.533-8.533V281.6z"/>
                <path d="M426.667,281.6c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V281.6z"/>
                <path d="M358.4,384c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V384z"/>
                <path d="M426.667,332.8c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V332.8z"/>
                <path d="M349.867,426.667c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V435.2
                  C358.4,430.49,354.577,426.667,349.867,426.667z"/>
                <path d="M358.4,332.8c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V332.8z"/>
                <path d="M384,477.867c4.71,0,8.533-3.823,8.533-8.533V196.267c0-3.917-2.671-7.33-6.46-8.277L249.54,153.856
                  c-2.534-0.64-5.257-0.06-7.322,1.553c-2.074,1.613-3.285,4.096-3.285,6.724v187.733c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V173.065l119.467,29.867v266.402C375.467,474.044,379.29,477.867,384,477.867z"/>
                <path d="M187.733,179.2c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V179.2z"/>
                <path d="M187.733,128c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V128z"/>
                <path d="M119.467,384c0-4.71-3.823-8.533-8.533-8.533S102.4,379.29,102.4,384v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V384z"/>
                <path d="M42.667,307.2c4.71,0,8.533-3.823,8.533-8.533V256h85.333v213.333c0,4.71,3.823,8.533,8.533,8.533
                  c4.71,0,8.533-3.823,8.533-8.533V247.467c0-4.71-3.823-8.533-8.533-8.533h-102.4c-4.71,0-8.533,3.823-8.533,8.533v51.2
                  C34.133,303.377,37.956,307.2,42.667,307.2z"/>
                <path d="M136.533,179.2v17.067c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533V179.2
                  c0-4.71-3.823-8.533-8.533-8.533C140.356,170.667,136.533,174.49,136.533,179.2z"/>
                <path d="M187.733,76.8c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V76.8z"/>
                <path d="M110.933,426.667c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V435.2
                  C119.467,430.49,115.644,426.667,110.933,426.667z"/>
                <path d="M503.467,494.933H486.4v-52.412c14.677-3.814,25.6-17.067,25.6-32.922c0-18.825-15.309-34.133-34.133-34.133
                  s-34.133,15.309-34.133,34.133c0,15.855,10.923,29.107,25.6,32.922v52.412H256v-34.995c19.447-3.968,34.133-21.197,34.133-41.805
                  c0-23.526-19.14-42.667-42.667-42.667c-23.526,0-42.667,19.14-42.667,42.667c0,20.608,14.686,37.837,34.133,41.805v34.995H51.2
                  v-86.195c19.447-3.968,34.133-21.197,34.133-41.805c0-23.526-19.14-42.667-42.667-42.667C19.14,324.267,0,343.407,0,366.933
                  c0,20.608,14.686,37.837,34.133,41.805v86.195h-25.6c-4.71,0-8.533,3.823-8.533,8.533S3.823,512,8.533,512h494.933
                  c4.71,0,8.533-3.823,8.533-8.533S508.177,494.933,503.467,494.933z M17.067,366.933c0-14.114,11.486-25.6,25.6-25.6
                  s25.6,11.486,25.6,25.6s-11.486,25.6-25.6,25.6S17.067,381.047,17.067,366.933z M221.867,418.133c0-14.114,11.486-25.6,25.6-25.6
                  s25.6,11.486,25.6,25.6s-11.486,25.6-25.6,25.6S221.867,432.247,221.867,418.133z M460.8,409.6
                  c0-9.412,7.654-17.067,17.067-17.067s17.067,7.654,17.067,17.067c0,9.412-7.654,17.067-17.067,17.067S460.8,419.012,460.8,409.6z
                  "/>
                <path d="M68.267,435.2v34.133c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V435.2c0-4.71-3.823-8.533-8.533-8.533
                  S68.267,430.49,68.267,435.2z"/>
                <path d="M119.467,281.6c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V281.6z"/>
                <path d="M119.467,332.8c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V332.8z"/>
                <path d="M68.267,281.6v17.067c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V281.6c0-4.71-3.823-8.533-8.533-8.533
                  S68.267,276.89,68.267,281.6z"/>
                <path d="M187.733,332.8c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V332.8z"/>
                <path d="M221.867,230.4c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  c4.71,0,8.533-3.823,8.533-8.533V230.4z"/>
                <path d="M221.867,179.2c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  c4.71,0,8.533-3.823,8.533-8.533V179.2z"/>
                <path d="M221.867,281.6c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  c4.71,0,8.533-3.823,8.533-8.533V281.6z"/>
                <path d="M213.333,324.267c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  c4.71,0,8.533-3.823,8.533-8.533V332.8C221.867,328.09,218.044,324.267,213.333,324.267z"/>
                <path d="M221.867,128c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  c4.71,0,8.533-3.823,8.533-8.533V128z"/>
                <path d="M187.733,384c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V384z"/>
                <path d="M110.933,221.867c4.71,0,8.533-3.823,8.533-8.533V150.34l29.414-14.703c2.893-1.451,4.719-4.403,4.719-7.637V56.474
                  l42.667-21.333l42.667,21.333V128c0,4.71,3.823,8.533,8.533,8.533S256,132.71,256,128V51.2c0-3.234-1.826-6.187-4.719-7.637
                  L204.8,20.326V8.533c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v11.793l-46.481,23.236
                  c-2.893,1.451-4.719,4.403-4.719,7.637v71.526l-29.414,14.703c-2.893,1.451-4.719,4.403-4.719,7.637v68.267
                  C102.4,218.044,106.223,221.867,110.933,221.867z"/>
                <path d="M187.733,281.6c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V281.6z"/>
                <path d="M179.2,426.667c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V435.2
                  C187.733,430.49,183.91,426.667,179.2,426.667z"/>
                <path d="M221.867,76.8c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  c4.71,0,8.533-3.823,8.533-8.533V76.8z"/>
                <path d="M187.733,230.4c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
                  s8.533-3.823,8.533-8.533V230.4z"/>
              </g>
            </g>
          </g>
          </svg>
          <div class="r-info">
            <div class="r-info-name">Отдел продаж новостроек</div>
            <div class="r-info-subtitle">ЖК Воронежа</div>
            <div class="contact-info">
              <svg fill="#FFFFFF" height="20" width="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM351.6 321.5l-11.62 50.39c-1.633 7.125-7.9 12.11-15.24 12.11c-126.1 0-228.7-102.6-228.7-228.8c0-7.328 4.984-13.59 12.11-15.22l50.38-11.63c7.344-1.703 14.88 2.109 17.93 9.062l23.27 54.28c2.719 6.391 .8828 13.83-4.492 18.22L168.3 232c16.99 34.61 45.14 62.75 79.77 79.75l22.02-26.91c4.344-5.391 11.85-7.25 18.24-4.484l54.24 23.25C349.5 306.6 353.3 314.2 351.6 321.5z"/></svg>
              <div>+7 (920) 434-12-21</div>
            </div>
            <div class="contact-info">
              <svg fill="#FFFFFF" height="20" width="20" version="1.1" viewBox="0 0 777 508" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><path d="M0.849684267,37.7112682 C-4.46720912,5.26682385 152.381103,25.3544974 152.381103,25.3544974 C152.381103,25.3544974 243.432877,254.010586 279.321897,254.010582 C315.210917,254.010578 297.931019,60.1164021 297.931019,60.1164021 C297.931019,60.1164021 253.402048,48.5291005 253.402049,30.7619048 C253.402051,12.994709 297.931019,0.634920635 297.931019,0.634920635 C297.931019,0.634920635 413.168963,0.634922188 428.859482,0.634920635 C444.55,0.634919061 450.794241,26.1269854 450.794257,43.1216931 C450.794272,60.1164008 450.794257,250.920635 450.794257,250.920635 L486.683277,250.920635 C486.683277,250.920635 518.630315,199.572804 543.837269,147.407407 C569.044225,95.2420119 599.664634,25.3544974 599.664634,25.3544974 L751.860664,25.3544974 C751.860664,25.3544974 779.774347,26.1209492 776.451289,49.2985698 C771.930545,80.8298057 670.67553,230.566247 649.510495,281.817088 C633.559819,320.441427 716.517779,372.719686 751.860664,428.589575 C774.798669,464.849881 776.451289,507.386243 776.451289,507.386243 L595.679561,507.386243 L505.289803,402.325025 C505.289803,402.325025 486.683277,394.603175 472.059228,405.414972 C457.43518,416.22677 456.773164,437.859416 456.773164,437.859416 L456.773164,507.386243 L315.210917,507.386243 C315.210917,507.386243 206.879245,470.306878 115.16286,308.084656 C23.4464649,145.862436 6.16657616,70.1557126 0.849684267,37.7112682 Z" fill="#FFFFFF" id="vkontakte"/></g></svg>
              <div><a target="_blank" href="https://vk.com/club223850485">мы ВКонтакте</a></div>
            </div>
            <div class="contact-info">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#FFFFFF" viewBox="0 0 16 16"> <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/> </svg>
              <div>Московский проспект, 10</div>
            </div>
          </div>
      </div>
    </header>

    <flow-form
      ref="flowform"
      v-on:complete="onComplete"
      v-on:submit="onQuizSubmit"
      v-bind:questions="questions"
      v-bind:language="language"
      v-bind:standalone="true"
    >

      <template v-slot:complete>
        <template v-if="!submitted">
          <p>
            <span class="fh2">Отлично!</span>
            <span class="f-section-text">
              Проверьте, пожалуйста, правильность информации и нажмите "Подтвердить".
            </span>
          </p>
          <div class="answers-block">
            <template v-for="question of questions" :key="question.id">
              <div v-if="question.id !== 'start'">
                <span class="field-name">{{ question.shortTitle }}: </span><span class="field-value">{{ Array.isArray(question.answer) ? question.answer.join(', ') : question.answer }}</span>
              </div>
            </template>
          </div>
        </template>
        <div v-if="submitted">
          <p class="">
            <span class="fh2">Спасибо!</span>
          </p>
        </div>
      </template>

      <template v-slot:completeButton>
        <div class="f-submit" v-if="!submitted">
          <button
            class="o-btn-action"
            ref="button"
            type="submit"
            href="#"
            v-on:click.prevent="onSubmit()"
            aria-label="Press to submit">
            <span>Подтвердить</span>
          </button>

        </div>
      </template>
    </flow-form>
  </div>
</template>

<script>
  import axios from 'axios'
  import FlowForm from '../../src/components/FlowForm.vue'
  import QuestionModel, { QuestionType, ChoiceOption, LinkOption } from '../../src/models/QuestionModel'
  import LanguageModel from '../../src/models/LanguageModel'

  export default {
    name: 'example',
    components: {
      FlowForm
    },
    data() {
      return {
        submitted: false,
        completed: false,
        score: 0, 
        total: 8, 
        time: 0,
        formattedTime: '',
        answers: {
          html_1: ['2', '3'], 
          html_2: 'false', 
          html_3: '2', 
          html_4: ['3', '4'],  
          ux_1: ['1', '2', '4'], 
          ux_2: 'false', 
          ux_3: '4', 
          ux_4: 'true', 
        },
        language: new LanguageModel(),
        questions: [      
          new QuestionModel({
            id: 'start',
            tagline: 'всего за несколько простых шагов',
            title: 'Подберите идеальную квартиру в лучших новостройках Воронежа',
            content: 'напрямую от застройщика',
            type: QuestionType.SectionBreak,
            required: true,
          }),
          new QuestionModel({
            id: 'rooms',
            shortTitle: 'тип квартиры',
            title: 'Какую квартиру Вы хотите купить?',
            helpText: 'можно указать один или несколько вариантов',
            type: QuestionType.MultipleChoice,
            required: true,
            multiple: true,
            options: [
              new ChoiceOption({
                label: 'Студию', 
                value: 'студия'
              }),
              new ChoiceOption({
                label: '1-комнатную', 
                value: '1-комнатная'
               }),
              new ChoiceOption({
                label: '2-комнатную', 
                value: '2-комнатная'
              }), 
              new ChoiceOption({
                label: '3-комнатную', 
                value: '3-комнатная'
              }),
              new ChoiceOption({
                label: '4-комнатную', 
                value: '4-комнатная'
              })
            ]
          }),
          new QuestionModel({
            id: 'paytype',
            shortTitle: 'виды оплаты',
            title: 'Какие варианты оплаты рассматриваете?',
            type: QuestionType.MultipleChoice,
            multiple: true,
            required: true,
            options: [
              new ChoiceOption({
                label: 'Собственные средства',
                value: 'собственные средства'
              }),
              new ChoiceOption({
                label: 'Ипотека',
                value: 'ипотека'
              }),
              new ChoiceOption({
                label: 'Военная ипотека',
                value: 'военная ипотека' 
              }),
              new ChoiceOption({
                label: 'Рассрочка', 
                value: 'рассрочка'
              }),
              new ChoiceOption({
                label: 'Материнский капитал', 
                value: 'материнский капитал'
              })
            ]
          }),
          new QuestionModel({
            id: 'phone',
            shortTitle: 'телефон',
            title: 'Укажите номер телефона для связи с Вами',
            helpText: 'десять цифр без восмерки',
            type: QuestionType.Phone,
            required: true,
            mask: '+7 (###) ###-##-##'
          }),
        ]
      }
    },
    methods: {
      onComplete(completed, questionList) {
        this.completed = completed
      },

      onSubmit() {
        const data = this.getData()

        axios.post('https://api.новое-жилье36.рф/public/api/lead/add', {
          data,
        }, { withCredentials: true })
        .then((response) => {
          console.log(response)
          this.$refs.flowform.submitted = true
          this.submitted = true
        })
      },
      getData() {
        const data = {
          forEmail: {},
          forDataBase: {}
        }

        this.questions.forEach(question => {
          if (question.id !== 'start') {
            let answer = question.answer
            if (Array.isArray(answer)) {
              answer = answer.join(', ')
            }

            data.forEmail[question.shortTitle] = answer
            data.forDataBase[question.id] = answer
          }
        })

        return data
      }
    },
  }
</script>

<style lang="css">
  @import '../../src/assets/css/themes/theme-vrn.css';

  .f-container {
    display: flex;
  }

  .r-info {
    color: #FFFFFF;
    margin-left: 16px;
  }

  .r-info-name {
    font-size: 1.3rem;
    font-weight: bold;
  }

  .r-info-subtitle {
    margin-bottom: 9px;
  }

  .contact-info {
    display: flex;
  }

  .contact-info div {
    margin-left: 7px;
  }

  .contact-info a {
    text-decoration: none;
    color: #FFFFFF;
  }

  .answers-block {
    margin-bottom: 15px;
  }

  .field-value {
    font-weight: 900;
  }
</style>